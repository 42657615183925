<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('DELIVERYCOND.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('DELIVERYCOND.HEADER.TEXT')}}</span>
        </p>
        <AddDeliveryConditionDlg ref="addDeliveryConditionDlg" v-on:Success="LoadData"></AddDeliveryConditionDlg>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddDeliveryCondtion">{{$t('DELIVERYCOND.ADD')}}</vs-button>
            </div> 
            <div id="deliveryconditions_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="deliveryconds"
                :noDataText="$t('DELIVERYCOND.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DELIVERYCOND.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('DELIVERYCOND.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="description">
                    {{$t('DELIVERYCOND.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="country_fk">
                    {{$t('DELIVERYCOND.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="currency_fk">
                    {{$t('DELIVERYCOND.TABLE.COL4')}}
                  </vs-th>  
                  <vs-th sort-key="price">
                    {{$t('DELIVERYCOND.TABLE.COL5')}}
                  </vs-th>
                  <vs-th sort-key="isDefault">
                    {{$t('DELIVERYCOND.TABLE.COL6')}}
                  </vs-th>                                                                                                          
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>                  
                    <vs-td :data="tr.description">
                      {{LimitTextTo(tr.description,150)}}
                    </vs-td>     
                    <vs-td :data="tr.country_fk">
                      {{GetNameOfCountry(tr.country_fk)}}
                    </vs-td> 
                    <vs-td :data="tr.currency_fk">
                      {{GetNameOfCurrency(tr.currency_fk)}}
                    </vs-td>   
                    <vs-td :data="tr.price">
                      {{GetCurrencyString(tr.price,tr.language_fk,tr.currency_kuerzel)}}
                    </vs-td> 
                    <vs-td :data="tr.isDefault">
                      <vs-checkbox :disabled="true" v-model="tr.isDefault"></vs-checkbox>
                    </vs-td>                                                                                                
                    <vs-td>
                      <vs-button  @click="EditDeliveryCondtion(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteDeliveryCondition(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import AddDeliveryConditionDlg from './components/dialogs/AddDeliveryConditionDlg.vue';

export default {
  name: "DeliveryConditions",
  components: {
    AddDeliveryConditionDlg
  },
    data: function (){
      return {
      deliveryconds:[]
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.deliverycondition.deliveryconditions.data != null)
      {
        data = this.$store.state.deliverycondition.deliveryconditions.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.deliverycondition.deliveryconditions.status) != 'undefined')
        loading = this.$store.state.deliverycondition.deliveryconditions.status.loading;
      return loading;
    },

    languages(){
      return this.GetListOfLanguagesForSelect();
    },
    DocTypes(){
      return this.GetListOfDokumentTypeForSelect();
    }
  },
  watch: {
      getTableData(value) {
          this.deliveryconds = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#deliveryconditions_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#deliveryconditions_table'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('deliverycondition/getDeliveryConditions'); 
      },
      EditDeliveryCondtion(data){
        this.$refs.addDeliveryConditionDlg.InitDlgData(data);
      },
      AddDeliveryCondtion(){
        this.$refs.addDeliveryConditionDlg.ShowDlg();
      },
      GetCurrencyString(amount,language_fk,currency_kuerzel){
        if(this.IsNotNull(amount) && this.IsNotNull(language_fk) && this.IsNotNull(currency_kuerzel))
        {
          return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
        }
        else
        {
          return "";
        }
      },   
      OpenConfirmDeleteDeliveryCondition(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DELIVERYCOND.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('deliverycondition/deleteDeliveryCondition', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DELIVERYCOND.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('DELIVERYCOND.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DELIVERYCOND.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>