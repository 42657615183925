<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddDeliveryCondition"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('DELIVERYCOND.ADD_DELIVERYCOND.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('DELIVERYCOND.ADD.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('DELIVERYCOND.ADD.LABEL.PLACEHOLDER.NAME')" v-model="data.name"  :danger="name_fail" val-icon-danger="clear"/>
            <vs-input :label="$t('DELIVERYCOND.ADD.LABEL.DESCRIPTION')"  class="float-left mr-2 inputx" :placeholder="$t('DELIVERYCOND.ADD.LABEL.PLACEHOLDER.DESCRIPTION')" v-model="data.description" :danger="description_fail" val-icon-danger="clear"/>
            <vs-input type="number" :label="$t('DELIVERYCOND.ADD.LABEL.AMOUNT')"  class="float-left mr-2 inputx" :placeholder="0" v-model="data.price"/>
          </div>
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-select :label="$t('DELIVERYCOND.ADD.LABEL.COUNTRY')" v-model="data.country_fk" :danger="country_fk_fail">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
              </vs-select>
            </div>
            <div  class="float-left mr-2">
              <vs-select :label="$t('DELIVERYCOND.ADD.LABEL.WAEHRUNG')" v-model="data.currency_fk" :danger="currency_fk_fail">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
              </vs-select>
            </div>
          </div>  
          <div class="default-input clearfix align-items-center mb-3">
            <div  class="float-left mr-2">
              <vs-checkbox v-model="data.isDefault">{{$t('DELIVERYCOND.ADD_DELIVERYCOND.LABEL.ISDEFAULT')}}</vs-checkbox>
            </div>
          </div>  
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddDeliveryConditionDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('DELIVERYCOND.ADD_DELIVERYCOND.TITLE'),
      name_fail:false,
      name_ok:false,
      description_fail:false,
      description_ok:false,
      currency_fk_fail:false,
      currency_fk_ok:false,
      country_fk_fail:false,
      country_fk_ok:false, 
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('DELIVERYCOND.ADD_DELIVERYCOND.TITLE');

        this.SelectFirstCountryCurrency();
        
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.SelectFirstCountryCurrency();
          this.popupTitle = this.$t('DELIVERYCOND.ADD_DELIVERYCOND.TITLE.EDIT');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      accepted(){

          this.$store.dispatch('deliverycondition/saveDeliveryCondition', this.data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DELIVERYCOND.ADD_DELIVERYCOND.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t(response.text);
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DELIVERYCOND.ADD_DELIVERYCOND.WARNING.SAVE').replace("*1*",text)}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DELIVERYCOND.ADD_DELIVERYCOND.ERROR.SAVE').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
          name:"",
          description:"",
          company_fk:-1,
          language_fk:-1,
          currency_fk:-1,
          country_fk:-1,
          currency_kuerzel:"",
          price:0,
          changedby:""
        };
      },
      SelectFirstCountryCurrency(){
        if(this.IsNotNull(this.countries))
        {
          if(this.countries.length > 1)
          {
            this.data.country_fk = this.countries[1].value;
          }
        }
        if(this.IsNotNull(this.currencies))
        {
          if(this.currencies.length > 1)
          {
            this.data.currency_fk = this.currencies[1].value;
          }
        }
      }
  },
  computed:{
    formValid(){
      return this.name_ok && this.description_ok && this.country_fk_ok && this.currency_fk_ok;
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
    name(){
      return this.data.name;
    },
    description(){
      return this.data.description;
    },
    country_fk(){
      return this.data.country_fk;
    },
    currency_fk(){
      return this.data.currency_fk;
    }
  },
  watch:{
    name(value)
    {
       this.name_fail = value.length == 0;
       this.name_ok = !this.name_fail;
    },
    description(value)
    {
      this.description_fail = value.length == 0;
      this.description_ok = !this.description_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    currency_fk(value){
      this.currency_fk_fail = value <= 0;
      this.currency_fk_ok = !this.currency_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddDeliveryCondition > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddDeliveryCondition > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>